// ** Initial State
const initialState = {
  pricingRange: {},
  total: 1,
  params: {},
  myCustomPaginatin: "",
  selectedPlan:
    localStorage.getItem("selectedPlan") === "undefined"
      ? null
      : JSON.parse(localStorage.getItem("selectedPlan")),
  loading: false,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: "",
  entries_per_page: "",
  total_results: "",
  updatePagination: 0,
};

// console.log('sssssssssssss', selectedUser)

const ranges = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_RANGES":
      // console.log(action.data)
      return {
        ...state,
        pricingRange: action.data,
        myCustomPaginatin: action.total,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };
    case "UPDATE_GLOBAL_PRICING_RANGE_FROM":
      const { index, newValue } = action.payload;
      const updatedRanges = [...state.pricingRange.ranges];
      updatedRanges[index] = { ...updatedRanges[index], from: newValue };

      return {
        ...state,
        pricingRange: {
          ...state.pricingRange,
          ranges: updatedRanges,
        },
      };

    case "UPDATE_GLOBAL_PRICING_RANGE_TO":
      const { index: toIndex, newValue: toValue } = action.payload;
      const updatedRangesTo = [...state.pricingRange.ranges];
      updatedRangesTo[toIndex] = { ...updatedRangesTo[toIndex], to: toValue };

      return {
        ...state,
        pricingRange: {
          ...state.pricingRange,
          ranges: updatedRangesTo,
        },
      };

    case "UPDATE_GLOBAL_PRICING_RANGE_PRICE":
      const { index: priceIndex, newValue: priceValue } = action.payload;
      const updatedRangesPrice = [...state.pricingRange.ranges];
      updatedRangesPrice[priceIndex] = {
        ...updatedRangesPrice[priceIndex],
        price: priceValue,
      };

      return {
        ...state,
        pricingRange: {
          ...state.pricingRange,
          ranges: updatedRangesPrice,
        },
      };

    case "SET_MINIMUM_CREDIT":
      return {
        ...state,
        pricingRange: {
          ...state.pricingRange,
          credit: action.payload,
        },
      };

    case "INITIAL_REMOVE_RANGES":
      return { ...state, pricingRange: {} };

    default:
      return { ...state };
  }
};
export default ranges;
